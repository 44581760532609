import React, { useState } from 'react';
import axios from 'axios';

function Contact() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });

    const [responseMessage, setResponseMessage] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post('/api/send_contact_email/', formData);
            if (response.data.success) {
                setResponseMessage('Your message has been successfully sent.');
                setFormData({ name: '', email: '', message: '' }); // Reset the form
            }
        } catch (error) {
            setResponseMessage('Error sending email. Please try again.');
        }
    };

    return (
        <div className="w-full bg-white py-16">
            <div className="container mx-auto px-6 lg:px-12">
                <h2 className="text-4xl font-bold text-center mb-8">Contact Us</h2>
                <p className="text-center mb-4">If you have a question or have a business inquiry, please fill out the form and I’ll be happy to answer. I’ll respond within 2 business days.</p>
                <form onSubmit={handleSubmit} className="max-w-4xl mx-auto bg-white shadow-md rounded px-8 py-8">
                    <div className="mb-6">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
                            Your Name
                        </label>
                        <input
                            type="text"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            placeholder="Enter your name"
                            required
                        />
                    </div>
                    <div className="mb-6">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                            Your Email
                        </label>
                        <input
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            placeholder="Enter your email address"
                            required
                        />
                    </div>
                    <div className="mb-6">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="message">
                            Your Message
                        </label>
                        <textarea
                            name="message"
                            value={formData.message}
                            onChange={handleChange}
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            placeholder="Enter your message"
                            rows="6"
                            required
                        ></textarea>
                    </div>
                    <div className="flex items-center justify-center">
                        <button
                            type="submit"
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        >
                            Send Message
                        </button>
                    </div>
                    {responseMessage && (
                        <p className="mt-4 text-center text-green-500">{responseMessage}</p>
                    )}
                </form>

                {/* Social Media Icons */}
                <div className="flex justify-center space-x-8 mt-12">
                    {/* Instagram */}
                    <a href="https://www.instagram.com/finitrade/" target="_blank" rel="noreferrer">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 text-gray-700 hover:text-yellow-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M7 2a5 5 0 00-5 5v10a5 5 0 005 5h10a5 5 0 005-5V7a5 5 0 00-5-5H7z" />
                            <path strokeLinecap="round" strokeLinejoin="round" d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37z" />
                            <path strokeLinecap="round" strokeLinejoin="round" d="M17.5 6.5h.01" />
                        </svg>
                    </a>

                    {/* TikTok */}
                    <a href="https://www.tiktok.com/@finitrade" target="_blank" rel="noreferrer">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 text-gray-700 hover:text-yellow-500" viewBox="0 0 24 24" fill="currentColor" stroke="currentColor" strokeWidth={0}>
                            <path d="M12.297 2c-.232 0-.46.013-.684.038v9.713c0 1.426-1.157 2.584-2.585 2.584a2.585 2.585 0 01-.525-5.12V7.073a5.426 5.426 0 000 10.852 5.426 5.426 0 005.426-5.425V7.762c.616.437 1.378.695 2.188.695h.372V5.686h-.372c-1.206 0-2.188-.982-2.188-2.188V2h-1.632z" />
                        </svg>
                    </a>

                    {/* Twitter */}
                    <a href="https://x.com/finitrade" target="_blank" rel="noreferrer">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 text-gray-700 hover:text-yellow-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M23 3a10.9 10.9 0 01-3.14 1.53A4.48 4.48 0 0016.5 2 4.48 4.48 0 0012 6.5V7a11.35 11.35 0 01-8.2-4.1S1 8.28 5 10.92c-.9.4-3 .2-3 .2s1.6 1.6 4 2c-1 .1-3 .2-3 .2a9.28 9.28 0 009 6 11.23 11.23 0 0011.67-11.5v-.5A7.72 7.72 0 0023 3z" />
                        </svg>
                    </a>
                </div>
            </div>
        </div>
    );
}

export default Contact;
