import React, { useState } from 'react';
import axios from 'axios';

function Calculator() {
    const [compoundData, setCompoundData] = useState({
        currentInvestment: '',
        annualContribution: '',
        years: '',
        interestRate: '',
    });
    const [compoundResult, setCompoundResult] = useState(null);

    const [dividendData, setDividendData] = useState({
        currentInvestment: '',
        annualContribution: '',
        dividendYield: '',
        years: '',
        dividendGrowthRate: ''
    });
    const [dividendResult, setDividendResult] = useState(null);

    // Handle changes for inputs
    const handleChange = (e, setStateFunction) => {
        setStateFunction(prevState => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }));
    };

    // Function to calculate compound interest
    const calculateCompoundInterest = async () => {
        const response = await axios.post('/api/calculate_compound_interest/', compoundData);
        setCompoundResult(response.data);
    };

    // Function to calculate dividend growth
    const calculateDividendGrowth = async () => {
        const response = await axios.post('/api/calculate_dividend_growth/', dividendData);
        setDividendResult(response.data);
    };

    return (
        <div className="container mx-auto px-6 py-12">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
                {/* Compound Interest Calculator */}
                <div className="bg-gray-200 shadow-md rounded-lg p-6">
                    <h2 className="text-2xl font-bold mb-6">Annual Compound Interest Calculator</h2>
                    <div className="mb-4">
                        <label className="block mb-1">Initial Investment ($):</label>
                        <input
                            type="number"
                            name="currentInvestment"
                            value={compoundData.currentInvestment}
                            onChange={(e) => handleChange(e, setCompoundData)}
                            className="w-full border border-gray-300 p-2 rounded"
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block mb-1">How Much You Plan To Invest Annually ($):</label>
                        <input
                            type="number"
                            name="annualContribution"
                            value={compoundData.annualContribution}
                            onChange={(e) => handleChange(e, setCompoundData)}
                            className="w-full border border-gray-300 p-2 rounded"
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block mb-1">Years to Grow:</label>
                        <input
                            type="number"
                            name="years"
                            value={compoundData.years}
                            onChange={(e) => handleChange(e, setCompoundData)}
                            className="w-full border border-gray-300 p-2 rounded"
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block mb-1">Interest Rate (%):</label>
                        <input
                            type="number"
                            name="interestRate"
                            value={compoundData.interestRate}
                            onChange={(e) => handleChange(e, setCompoundData)}
                            className="w-full border border-gray-300 p-2 rounded"
                        />
                    </div>
                    <button
                        className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
                        onClick={calculateCompoundInterest}
                    >
                        Calculate Compound Interest
                    </button>

                    {compoundResult && (
                        <div className="mt-6 bg-gray-100 p-4 rounded-lg">
                            <h3 className="text-xl font-bold mb-4">Results:</h3>
                            <p>Total Invested: ${compoundResult.total_invested}</p>
                            <p>Total Interest Earned: ${compoundResult.total_interest_earned}</p>
                            <p>Total Portfolio Amount: ${compoundResult.total_portfolio}</p>
                        </div>
                    )}
                </div>

                {/* Dividend Growth Calculator */}
                <div className="bg-gray-200 shadow-md rounded-lg p-6">
                    <h2 className="text-2xl font-bold mb-6">Dividend Growth Calculator</h2>
                    <div className="mb-4">
                        <label className="block mb-1">Initial Investment ($):</label>
                        <input
                            type="number"
                            name="currentInvestment"
                            value={dividendData.currentInvestment}
                            onChange={(e) => handleChange(e, setDividendData)}
                            className="w-full border border-gray-300 p-2 rounded"
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block mb-1">How Much You Plan To Invest Annually ($):</label>
                        <input
                            type="number"
                            name="annualContribution"
                            value={dividendData.annualContribution}
                            onChange={(e) => handleChange(e, setDividendData)}
                            className="w-full border border-gray-300 p-2 rounded"
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block mb-1">Dividend Yield (%):</label>
                        <input
                            type="number"
                            name="dividendYield"
                            value={dividendData.dividendYield}
                            onChange={(e) => handleChange(e, setDividendData)}
                            className="w-full border border-gray-300 p-2 rounded"
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block mb-1">Dividend Growth Rate (%):</label>
                        <input
                            type="number"
                            name="dividendGrowthRate"
                            value={dividendData.dividendGrowthRate}
                            onChange={(e) => handleChange(e, setDividendData)}
                            className="w-full border border-gray-300 p-2 rounded"
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block mb-1">Years to Grow:</label>
                        <input
                            type="number"
                            name="years"
                            value={dividendData.years}
                            onChange={(e) => handleChange(e, setDividendData)}
                            className="w-full border border-gray-300 p-2 rounded"
                        />
                    </div>
                    <button
                        className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
                        onClick={calculateDividendGrowth}
                    >
                        Calculate Dividend Growth
                    </button>

                    {dividendResult && (
                        <div className="mt-6 bg-gray-100 p-4 rounded-lg">
                            <h3 className="text-xl font-bold mb-4">Results:</h3>
                            <p>Total Invested: ${dividendResult.total_invested}</p>
                            <p>Total Dividend Earned: ${dividendResult.total_dividend_earned}</p>
                            <p>Total Portfolio Amount: ${dividendResult.total_portfolio}</p>
                        </div>
                    )}
                </div>
            </div>
            {/* Explanation Section */}
            <section className="bg-white mt-16">
                <div className="container mx-auto px-6 lg:px-12">
                    <h2 className="text-2xl font-bold text-center mb-6">Understanding Compound Interest and Dividend Growth</h2>
                    <p className="text-lg leading-7 mb-6">
                        <strong>Compound Interest</strong> is one of the most powerful ways to grow your wealth over time. It works by allowing you to earn interest not just on your initial investment, but also on the interest that your investment earns year after year. This snowball effect accelerates the growth of your portfolio the longer you leave your investment untouched.
                    </p>
                    <p className="text-lg leading-7 mb-6">
                        <strong>Dividend Growth</strong> refers to the increase in the dividends paid out by a company over time. By investing in companies that regularly increase their dividend payouts, you can not only earn regular income but also benefit from the compounding effect of reinvesting those dividends back into the market. Over time, dividend growth can lead to significant portfolio growth.
                    </p>
                    <p className="text-lg leading-7 mb-6">
                        Both of these methods are key strategies for long-term investors looking to build wealth through smart, steady investments. Whether you're looking for income, growth, or a combination of both, these calculators will give you an idea of how your money can grow over time.
                    </p>
                </div>
            </section>
        </div>
    );
}

export default Calculator;
