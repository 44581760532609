// frontend/src/Blog.js
import React from 'react';

function Blog() {
    return (
        <div className="text-center mt-10">
            <h1 className="text-4xl font-bold text-blue-700">Blog Coming Soon</h1>
            <p className="mt-4 text-lg text-gray-700">
                We’re working hard to bring you insightful articles and tips on stock market investing and financial freedom. Stay tuned!
            </p>
        </div>
    );
}

export default Blog;
