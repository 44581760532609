import React from 'react';
import { Link } from 'react-router-dom';  // <-- Add this line
import backgroundVideo from './assets/background.mp4';
import profileImage from './assets/profile.png';
import productImage1 from './assets/investing101.png'; 
import productImage2 from './assets/investmentmastery.png';
import productImage3 from './assets/TickrMeter.png'; 
import reviewImage from './assets/review.png';

function Home() {
    return (
        <>
            {/* Background Video Section */}
            <div className="relative w-full h-96 md:h-[500px]">
                <video
                    className="absolute top-0 left-0 w-full h-full object-cover"
                    src={backgroundVideo}
                    autoPlay
                    loop
                    muted
                    playsInline
                ></video>

                {/* Centered Text Over Video */}
                <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
                    <h1 className="text-white text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-bold bg-black bg-opacity-50 p-4 rounded-lg text-center">
                        Learn How To Get Started In The Stock Market
                    </h1>
                </div>
            </div>

            {/* About Section - Below the Video */}
            <section className="relative bg-gray-200 py-24 lg:pb-72">
                <div className="container mx-auto flex flex-col lg:flex-row items-center justify-center gap-8 px-6 lg:px-12">
                    {/* Left side: Image */}
                    <div className="lg:w-1/3 w-full flex justify-center items-center lg:justify-start lg:items-start mb-8 lg:mb-0">
                        <img
                            src={profileImage}
                            alt="Profile"
                            className="rounded-full w-64 h-64 lg:w-80 lg:h-80 shadow-lg object-cover"
                        />
                    </div>

                    {/* Right side: Text */}
                    <div className="lg:w-2/3 w-full text-gray-800 lg:max-w-lg text-justify">
                        <h2 className="text-4xl font-bold mb-6 text-center lg:text-left">
                            Stack Assets & Buy Freedom
                        </h2>
                        <p className="text-lg leading-7 mb-4">
                            I'm Yohann, the founder of Finitrade. I created this platform because I know exactly how it feels to
                            be overwhelmed by the world of investing.
                        </p>
                        <p className="text-lg leading-7 mb-4">
                            Over a decade ago, when I first tried to navigate the stock market,
                            financial advisors turned me away because I didn’t have hundreds of thousands of dollars to invest.
                        </p>
                        <p className="text-lg leading-7 mb-4">
                            But instead of giving up, I took matters into my own hands. I've read all the books I could find, 
                            I've taken many training courses, from university to online courses, and built a deep understanding of personal finance and the stock market
                            through trial and error.
                        </p>
                        <p className="text-lg leading-7 mb-4">
                            Finitrade is the resource I wish I had when I started – a no-nonsense, beginner-friendly platform
                            that simplifies investing and empowers you to take control of your financial future.
                        </p>
                        <p className="text-lg leading-7 mb-4">
                            Whether you’re feeling overwhelmed by the complexities of the stock market or tired of feeling left out of 
                            wealth-building opportunities, I’m here to provide the tools, guidance, and community you need to succeed.
                        </p>
                    </div>
                </div>

                {/* SVG curve - covers full section bottom */}
                <svg
                    className="absolute inset-x-0 bottom-0 w-full"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 1440 320"
                >
                    <path
                        fill="#ffffff"
                        fillOpacity="1"
                        d="M0,256L48,245.3C96,235,192,213,288,197.3C384,181,480,171,576,165.3C672,160,768,160,864,176C960,192,1056,224,1152,213.3C1248,203,1344,149,1392,122.7L1440,96V320H0Z"
                    ></path>
                </svg>
            </section>

            {/* New Section - Product Box Section */}
            <section className="bg-white py-8">
                <div className="container mx-auto px-6 lg:px-12">
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-16">

                        {/* Product 1 */}
                        <div className="bg-gray-200 p-4 lg:p-6 rounded-lg shadow-lg text-center flex flex-col justify-between">
                            <div>
                                <img
                                    src={productImage1}
                                    alt="Product 1"
                                    className="rounded-lg mb-4 object-cover h-64 w-full"
                                />
                                <h3 className="text-xl font-bold mb-2">Investing 101: A Beginner’s Guide - Free</h3>
                                <p className="text-gray-700 mb-4">
                                    This bestseller <strong>35-page guide</strong> is your blueprint to easy stock market investing.
                                </p>
                                <p className="text-gray-700 mb-4">
                                    Made for beginners, this <strong>free ebook</strong> is your first step into the world of investing.
                                </p>
                            </div>
                            <Link to="/investing101" className="bg-gradient-to-r from-green-400 to-blue-500 text-white py-3 px-8 rounded-full text-lg font-extrabold shadow-lg hover:from-blue-500 hover:to-green-400 transform hover:scale-105 transition duration-300 ease-in-out">
                                Get for Free
                            </Link>
                        </div>

                        {/* Product 2 */}
                        <div className="bg-gray-200 p-4 lg:p-6 rounded-lg shadow-lg text-center flex flex-col justify-between">
                            <div>
                                <img
                                    src={productImage2}
                                    alt="Product 2"
                                    className="rounded-lg mb-4 object-cover h-64 w-full"
                                />
                                <h3 className="text-xl font-bold mb-2">Investment Mastery - Paid</h3>
                                <p className="text-gray-700 mb-4">
                                    This package includes <strong>everything you need</strong> to both manage and grow your money effectively.
                                </p>
                                <p className="text-gray-700 mb-4">
                                    <strong>Investment Mastery</strong> is perfect for those ready to dive deeper into the world of investing and wealth-building. 
                                </p>
                            </div>
                            <Link to="/investment-mastery" className="bg-gradient-to-r from-green-400 to-blue-500 text-white py-3 px-8 rounded-full text-lg font-extrabold shadow-lg hover:from-blue-500 hover:to-green-400 transform hover:scale-105 transition duration-300 ease-in-out">
                                Become a Student
                            </Link>
                        </div>

                        {/* Product 3 */}
                        <div className="bg-gray-200 p-4 lg:p-6 rounded-lg shadow-lg text-center flex flex-col justify-between">
                            <div>
                                <img
                                    src={productImage3}
                                    alt="Product 3"
                                    className="rounded-lg mb-4 object-cover h-64 w-full"
                                />
                                <h3 className="text-xl font-bold mb-2">TickrMeter - Paid</h3>
                                <p className="text-gray-700 mb-4">
                                    <strong>Stock tickers</strong> for your table. They call it the second best thing to a Bloomberg terminal.
                                </p>
                                <p className="text-gray-700 mb-4">
                                    TickerMeter is a <strong>physical stock ticker display</strong> for your home and office that displays the latest prices for your current stock, crypto or forex positions.
                                </p>

                                <p className="text-gray-700 mb-4">
                                    Free shipping with code "<strong>FINITRADE</strong>".
                                </p>
                            </div>
                            <a
                                href="https://tickrmeter.dk/FINITRADE"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="bg-gradient-to-r from-green-400 to-blue-500 text-white py-3 px-8 rounded-full text-lg font-extrabold shadow-lg hover:from-blue-500 hover:to-green-400 transform hover:scale-105 transition duration-300 ease-in-out"
                            >
                                Learn More
                            </a>
                        </div>

                    </div>
                </div>
            </section>

            {/* New Section - The Finitrade Community Wins */}
            <section className="bg-white py-24">
                <div className="container mx-auto px-6 lg:px-12">
                    <h2 className="text-3xl font-bold mb-8 text-center">The Finitrade Community Wins 📈</h2>
                    <p className="text-lg text-center mb-6">
                        Join the growing community of investors who have transformed their financial future with the help of Finitrade.
                    </p>

                    {/* Add your content for the community section here */}
                    <div className="text-center">
                        <img
                            src={reviewImage} // Replace with your image path
                            alt="Review"
                            className="mx-auto rounded-lg shadow-lg w-full max-w-md lg:max-w-2xl" // Adjusted size for large devices
                        />
                    </div>
                </div>
            </section>
        </>
    );
}

export default Home;
