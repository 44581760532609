import React from 'react';
import productImage2 from './assets/investmentmastery.png';
import quoteImage from './assets/citation.png';

function Product2() {
    return (
        <div className="min-h-screen bg-white flex flex-col items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
            {/* Product Title */}
            <h1 className="text-4xl font-extrabold text-gray-900 mb-6 text-center">
                Investment Mastery
            </h1>

            {/* Product Image */}
            <div className="w-full max-w-md lg:max-w-lg mb-8">
                <img
                    src={productImage2}
                    alt="Investment Mastery"
                    className="object-cover rounded-lg shadow-lg"
                />
            </div>

            {/* Call to Action */}
            <div className="mt-2 text-center mb-10">
                <a
                    href="https://buy.stripe.com/3csg1AcLgdaY8SI28n"
                    className="bg-gradient-to-r from-green-400 to-blue-500 text-white py-4 px-8 rounded-full text-lg font-extrabold shadow-lg hover:from-blue-500 hover:to-green-400 transform hover:scale-105 transition duration-300 ease-in-out"
                >
                    Get Investment Mastery Now
                </a>
            </div>

            {/* Product Description */}
            <div className="max-w-2xl text-justify">
                <h2 className="text-3xl font-extrabold leading-7 mb-4 text-left">
                    From $1,000 to $100,000 in 3 steps with stock market investing.
                </h2>
                <p className="text-lg leading-7 mb-4">
                    This new training shows you how to turn $1,000 into $100,000 in 3 simple steps. You're probably dreaming of making your first $100,000. The problem is, you don't know where to start to earn that money.
                </p>
                <p className="text-lg leading-7 mb-4">
                    Even when you're trying to invest or launch your business, the following thing usually happens:
                </p>
                <p className="text-lg leading-7 mb-4">
                    NOTHING.
                </p>
                <p className="text-lg leading-7 mb-4">
                    Either you are losing money, or you are making a small amount, but not enough to hope to reach $100,000 in the next few years.
                </p>
                <p className="text-lg leading-7 mb-4">
                    And if I can describe this situation to you so well, it's because I've experienced it myself.
                </p>
                <p className="text-lg leading-7 mb-4">
                    I made every beginner's mistake. I lost over $10,000 in my first 2 years as an investor. It took me a total of 9 years to turn my $1,000 into $100,000. 
                </p>
                <p className="text-lg leading-7 mb-4">
                    I want you to be able to avoid all these mistakes. That's why I've spent the last 8 months creating...
                </p>
                <h2 className="text-3xl font-extrabold leading-7 mb-4 text-left">
                    A simple and effective 3-step system to turn $1,000 into $100,000 through stock market investing.
                </h2>
                <p className="text-lg leading-7 mb-4">
                    I named this system <strong>"Investment Mastery"</strong> and I am the only trainer who teaches it.
                </p>
                <p className="text-lg leading-7 mb-4">
                    Of course my system isn't necessary. You can start from scratch like I did. It just took me 5 years to figure out what I was doing wrong, then 4 years to catch up and finally get to $100,000.
                </p>
                <p className="text-lg leading-7 mb-4">
                    So you can spend the next 9 years of your life investing on your own. You will maybe manage to get to that $100,000.
                </p>
                <p className="text-lg leading-7 mb-4">
                    Or else...
                </p>
                <p className="text-lg leading-7 mb-4">
                    You can easily follow my <strong>3 easy steps</strong>, which contain <strong>EVERYTHING you need to know to QUICKLY and RELIABLY reach $100,000 invested in the stock market.</strong>
                </p>

                {/* What You'll Learn */}
                <h2 className="text-3xl font-bold mt-8 mb-4 text-left">What You'll Learn</h2>
                <p className="text-lg leading-7 mb-2">✅ <strong>20+ Hours of Instant Training:</strong> Start learning right away with 10 years of knowledge in PDF (250+ pages).</p>
                <p className="text-lg leading-7 mb-2">✅ <strong>Step-by-Step Learning:</strong> Follow clear steps to generate your first $100K.</p>
                <p className="text-lg leading-7 mb-2">✅ <strong>5 Simple Investment Guides:</strong> Learn the basics of investing step by step.</p>
                <p className="text-lg leading-7 mb-2">✅ <strong>Portfolio Building Plan:</strong> Create your own investment portfolio with ease.</p>
                <p className="text-lg leading-7 mb-2">✅ <strong>Advanced Market Strategies:</strong> Understand the stock market better.</p>
                <p className="text-lg leading-7 mb-2">✅ <strong>Earn Passive Income:</strong> Learn how to make money with dividends.</p>
                <p className="text-lg leading-7 mb-2">✅ <strong>50+ Dividend Stocks List:</strong> Begin your investment journey with a list of great dividend stocks.</p>
                <p className="text-lg leading-7 mb-2">✅ <strong>Easy Broker Account Setup:</strong> Open your investment account and start investing easily.</p>
                <p className="text-lg leading-7 mb-2">✅ <strong>Wealth Management Checklist:</strong> Stay on track to achieve your financial goals.</p>
                <p className="text-lg leading-7 mb-2">✅ <strong>DCA Implementation Plan:</strong> Grow your wealth consistently over time.</p>
                <p className="text-lg leading-7 mb-2">✅ <strong>Practical Examples:</strong> See real-life examples to help you understand better.</p>
                <p className="text-lg leading-7 mb-2">✅ <strong>No Experience Needed:</strong> Perfect for beginners with no prior knowledge.</p>
                <p className="text-lg leading-7 mb-2">✅ <strong>Clear and Simple Language:</strong> Learn without getting confused by complex terms.</p>
                <p className="text-lg leading-7 mb-2">✅ <strong>Lifetime Access:</strong> Check the guides whenever you need help.</p>
                <p className="text-lg leading-7 mb-2">✅ <strong>Community bonus:</strong> Join an exclusive community of investors for free.</p>
                <p className="text-lg leading-7 mb-8">✅ <strong>And much more!</strong></p>

                {/* Call to Action */}
                <div className="mt-8 mb-10 text-center">
                    <a
                        href="https://buy.stripe.com/3csg1AcLgdaY8SI28n"
                        className="bg-gradient-to-r from-green-400 to-blue-500 text-white py-4 px-8 rounded-full text-lg font-extrabold shadow-lg hover:from-blue-500 hover:to-green-400 transform hover:scale-105 transition duration-300 ease-in-out"
                    >
                        I Want This
                    </a>
                </div>

                <h2 className="text-3xl font-extrabold leading-7 mb-4 text-left">
                    Master the art of stock market investing in 3 easy steps.
                </h2>
                <p className="text-lg leading-7 mb-4">
                    This course is packed with my best advice on how to invest with confidence. This is <strong>100% concrete, practical advice that I've developed over 9 years of experience, and which you can use immediately.</strong>
                </p>
                <p className="text-lg leading-7 mb-4">
                    I dare say it: if you have a small amount of capital (around $1,000), the content of this program is better than what you'll find in 99% of the courses.
                </p>
                <p className="text-lg leading-7 mb-4">
                    Plus, all the other courses that make sense cost at least $1000!
                </p>
                <p className="text-lg leading-7 mb-4">
                    I've designed Investment Mastery so that you can put the advice into practice as QUICKLY AS POSSIBLE. Because it's <strong>by taking action and investing</strong> that you'll start earning money faster.
                </p>

                {/* Quote Image */}
                <div className="w-full text-center mb-8">
                    <img
                        src={quoteImage}
                        alt="Quote"
                        className="object-cover rounded-lg shadow-lg inline-block"
                    />
                </div>

                <h2 className="text-3xl font-bold mt-8 mb-4 text-left">The Investment Mastery Guarantee</h2>
                <p className="text-lg leading-7 mb-4">
                    This program is designed to have a lasting impact on your financial future, with straightforward and practical content that’s easy to understand and quick to apply.
                </p>
                <p className="text-lg leading-7 mb-4">
                    You’ll be able to start implementing these strategies immediately and see tangible results in real time.
                </p>
                <p className="text-lg leading-7 mb-4">
                    I’m so confident that Investment Mastery will be a game changer for you that I’m offering a simple guarantee:
                </p>
                <p className="text-lg leading-7 mb-4">
                    If this program doesn’t help you change the course of your financial future, just DM me for a full refund. No questions asked.
                </p>
            </div>

            {/* Call to Action */}
            <div className="mt-8 text-center">
                <a
                    href="https://buy.stripe.com/3csg1AcLgdaY8SI28n"
                    className="bg-gradient-to-r from-green-400 to-blue-500 text-white py-4 px-8 rounded-full text-lg font-extrabold shadow-lg hover:from-blue-500 hover:to-green-400 transform hover:scale-105 transition duration-300 ease-in-out"
                >
                    Get Investment Mastery Now
                </a>
            </div>
        </div>
    );
}

export default Product2;
