import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Home from './Home';
import Blog from './Blog';
import Calculator from './Calculator';
import Contact from './Contact';
import Product1 from './Product1'; // Import Product1
import Product2 from './Product2'; // Import Product2
import DownloadGuide from './DownloadGuide'; 
import DownloadAllGuides from './DownloadAllGuides';
import logo from './assets/logo.png'; // Path to your logo


function App() {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <Router>
            <div className="min-h-screen flex flex-col bg-white">
                {/* Navigation Bar */}
                <nav className="bg-white p-0 shadow-lg w-full z-10">
                    <div className="flex justify-between items-center w-full px-8">
                        {/* Logo aligned to the left */}
                        <div className="text-gray-800">
                            <Link to="/">
                                <img src={logo} alt="Finitrade Logo" className="h-24 w-auto" /> {/* Larger logo */}
                            </Link>
                        </div>

                        {/* Hamburger button for mobile */}
                        <div className="lg:hidden">
                            <button
                                className="text-gray-800 focus:outline-none"
                                onClick={() => setIsOpen(!isOpen)}
                            >
                                <svg
                                    className={`w-6 h-6 transform transition-transform duration-300 ${
                                        isOpen ? 'rotate-90' : ''
                                    }`}
                                    fill="none"
                                    stroke="currentColor"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d={
                                            isOpen
                                                ? 'M6 18L18 6M6 6l12 12' // Cross for closing
                                                : 'M4 6h16M4 12h16m-7 6h7' // Hamburger for opening
                                        }
                                    />
                                </svg>
                            </button>
                        </div>

                        {/* Navigation links and social media aligned to the right */}
                        <div
                            className={`lg:flex lg:items-center transition-all duration-300 ease-in-out ${
                                isOpen ? 'block' : 'hidden'
                            } absolute lg:static top-16 left-0 w-full lg:w-auto bg-white lg:bg-transparent`}
                        >
                            <ul className="flex flex-col lg:flex-row lg:ml-auto items-center space-y-4 lg:space-y-0 lg:space-x-12 p-4 lg:p-0">
                                <li>
                                    <Link to="/" className="text-gray-800 hover:text-yellow-500">
                                        Home
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/blog" className="text-gray-800 hover:text-yellow-500">
                                        Blog
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/calculator" className="text-gray-800 hover:text-yellow-500">
                                        Interest & Dividend Calculator
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/contact" className="text-gray-800 hover:text-yellow-500">
                                        Contact
                                    </Link>
                                </li>

                                {/* Social Media Links */}
                                <li className="flex space-x-4">
                                    {/* Instagram */}
                                    <a href="https://www.instagram.com/finitrade/" target="_blank" rel="noreferrer">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-gray-800 hover:text-yellow-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M7 2a5 5 0 00-5 5v10a5 5 0 005 5h10a5 5 0 005-5V7a5 5 0 00-5-5H7z" />
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37z" />
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M17.5 6.5h.01" />
                                        </svg>
                                    </a>

                                    {/* TikTok */}
                                    <a href="https://www.tiktok.com/@finitrade" target="_blank" rel="noreferrer">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-gray-800 hover:text-yellow-500" viewBox="0 0 24 24" fill="currentColor" stroke="currentColor" strokeWidth={0}>
                                            <path d="M12.297 2c-.232 0-.46.013-.684.038v9.713c0 1.426-1.157 2.584-2.585 2.584a2.585 2.585 0 01-.525-5.12V7.073a5.426 5.426 0 000 10.852 5.426 5.426 0 005.426-5.425V7.762c.616.437 1.378.695 2.188.695h.372V5.686h-.372c-1.206 0-2.188-.982-2.188-2.188V2h-1.632z" />
                                        </svg>
                                    </a>

                                    {/* Twitter */}
                                    <a href="https://x.com/finitrade" target="_blank" rel="noreferrer">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-gray-800 hover:text-yellow-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M23 3a10.9 10.9 0 01-3.14 1.53A4.48 4.48 0 0016.5 2 4.48 4.48 0 0012 6.5V7a11.35 11.35 0 01-8.2-4.1S1 8.28 5 10.92c-.9.4-3 .2-3 .2s1.6 1.6 4 2c-1 .1-3 .2-3 .2a9.28 9.28 0 009 6 11.23 11.23 0 0011.67-11.5v-.5A7.72 7.72 0 0023 3z" />
                                        </svg>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>

                {/* Routes */}
                <div className="flex-grow">
                    <Routes>
                        <Route exact path="/" element={<Home />} />
                        <Route path="/blog" element={<Blog />} />
                        <Route path="/calculator" element={<Calculator />} />
                        <Route path="/contact" element={<Contact />} />

                        {/* Product Pages - Only accessible via buttons */}
                        <Route path="/investing101" element={<Product1 />} />
                        <Route path="/investment-mastery" element={<Product2 />} />
                        <Route path="/download-guide" element={<DownloadGuide />} />
                        <Route path="/download-all-guides" element={<DownloadAllGuides />} />  
                    </Routes>
                </div>

                {/* Footer */}
                <footer className="bg-gray-800 text-white py-8">
                    <div className="container mx-auto px-6 lg:px-12 text-center">
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                            {/* Column 1: About Finitrade */}
                            <div>
                                <h3 className="text-xl font-bold mb-4">About this website</h3>
                                <p>
                                    This website has been prepared for informational purposes only and is not intended to be used for financial advice. Past performance is not indicative of future results and can fluctuate due to changing market conditions and changing economic conditions. 
                                </p>                                
                            </div>

                            {/* Column 2: Quick Links */}
                            <div>
                                <h3 className="text-xl font-bold mb-4">Quick Links</h3>
                                <ul className="space-y-2">
                                    <li>
                                        <Link to="/" className="hover:underline">Home</Link>
                                    </li>
                                    <li>
                                        <Link to="/blog" className="hover:underline">Blog</Link>
                                    </li>
                                    <li>
                                        <Link to="/calculator" className="hover:underline">Interest & Dividend Calculator</Link>
                                    </li>
                                    <li>
                                        <Link to="/contact" className="hover:underline">Contact</Link>
                                    </li>
                                </ul>
                            </div>

                            {/* Column 3: Social Media */}
                            <div>
                                <h3 className="text-xl font-bold mb-4">Follow Us</h3>
                                <ul className="flex justify-center space-x-4">
                                    {/* Instagram */}
                                    <li>
                                        <a href="https://www.instagram.com/finitrade/" target="_blank" rel="noreferrer">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M7 2a5 5 0 00-5 5v10a5 5 0 005 5h10a5 5 0 005-5V7a5 5 0 00-5-5H7z" />
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37z" />
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M17.5 6.5h.01" />
                                            </svg>
                                        </a>
                                    </li>
                                    {/* TikTok */}
                                    <li>
                                        <a href="https://www.tiktok.com/@finitrade" target="_blank" rel="noreferrer">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 24 24" fill="currentColor" stroke="currentColor" strokeWidth={0}>
                                                <path d="M12.297 2c-.232 0-.46.013-.684.038v9.713c0 1.426-1.157 2.584-2.585 2.584a2.585 2.585 0 01-.525-5.12V7.073a5.426 5.426 0 000 10.852 5.426 5.426 0 005.426-5.425V7.762c.616.437 1.378.695 2.188.695h.372V5.686h-.372c-1.206 0-2.188-.982-2.188-2.188V2h-1.632z" />
                                            </svg>
                                        </a>
                                    </li>
                                    {/* Twitter */}
                                    <li>
                                        <a href="https://x.com/finitrade" target="_blank" rel="noreferrer">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M23 3a10.9 10.9 0 01-3.14 1.53A4.48 4.48 0 0016.5 2 4.48 4.48 0 0012 6.5V7a11.35 11.35 0 01-8.2-4.1S1 8.28 5 10.92c-.9.4-3 .2-3 .2s1.6 1.6 4 2c-1 .1-3 .2-3 .2a9.28 9.28 0 009 6 11.23 11.23 0 0011.67-11.5v-.5A7.72 7.72 0 0023 3z" />
                                            </svg>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="text-center mt-8">
                            <p>I am not a financial advisor, therefore any information given or spoken about is for educational purposes only.</p>
                            <br></br>
                            <p>&copy; {new Date().getFullYear()} Finitrade. All Rights Reserved.</p>
                        </div>
                    </div>
                </footer>
            </div>
        </Router>
    );
}

export default App;
